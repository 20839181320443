document.addEventListener('DOMContentLoaded', function() {
  let cookieBanner = document.querySelector('.cookie-banner');
  let acceptButton = document.querySelector('.cookie-banner .black-button');

  if (localStorage.getItem('cookieConfirmed')) {
    cookieBanner.style.display = 'none';
  } else {
    cookieBanner.style.display = 'block';

    acceptButton.addEventListener('click', function(e) {
      e.preventDefault();

      localStorage.setItem('cookieConfirmed', true);
      cookieBanner.style.display = 'none';
    });
  }
});
